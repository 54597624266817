import slugify from 'slugify';

// File to hold general helper functions.

/**
 * Group a list of items by their related term name.
 *
 * @param {*} items
 * @param {*} termFieldName
 * @param {*} itemResolver
 */
export const groupItemsByTerm = (items, termFieldName, itemResolver) => {
  // Group KDDs by term name.
  let groupedItems = {};
  Object.keys(items).forEach((delta) => {
    const item = items[delta];
    const termName = item.relationships[termFieldName].name;
    const resolvedItem = itemResolver(item);
    if (!groupedItems[termName]) {
      groupedItems[termName] = [];
    }
    groupedItems[termName].push(resolvedItem);
  });
  return groupedItems;
};

/**
 * Gets the category line for an article display.
 *
 * @param {*} article
 *   Any type of article as an object.
 */
export const getArticleCategory = (article) =>
  // Either first intervention type, or institution.
  article.relationships?.interventionType[0]?.name ||
  article.relationships?.institutions?.name ||
  '';

/**
 * Given an array of HTML ID parts, construct an attribute-safe ID string.
 *
 * This is used in table-of-contents header and section IDs.
 *
 * @param {Array} idArray
 *   A list of ID parts.
 */
export const getIdString = (idArray) =>
  idArray
    .map((value) => slugify(value.toLowerCase()))
    .reduce((prev, curr) => `${prev}--${curr}`);

/**
 * Build a notes-widget ID.
 *
 * @param {Array} context
 *   A list of keys that define this html chunk's position within the article.
 *   For instance, ["overview", "background"]. This is how we build the ID for
 *   annotations.
 *
 * @param {*} index
 *   A numerical index for this notable, for instance if it is the third paragraph
 *   in a section.
 */
export const buildNotableId = (context, index) =>
  `${context
    .map((contextMember) => slugify(contextMember).toLowerCase())
    .join('.')}.${index}`;

/**
 * A convenience export for the full JSONAPI base url.
 */
export const jsonapiBaseUrl = `${process.env.BASE_URL}${process.env.API_BASE}`;

/**
 * Gets JSONAPI auth headers, if necessary.
 */
export const getJsonapiAuthHeaders = () => {
  if (process.env.BASIC_AUTH_USERNAME && process.env.BASIC_AUTH_PASSWORD) {
    return {
      withCredentials: true,
      credentials: 'include',
      headers: {
        Authorization: window.btoa(
          'Basic ' +
            btoa(
              process.env.BASIC_AUTH_USERNAME +
                ':' +
                process.env.BASIC_AUTH_PASSWORD
            )
        )
      }
    };
  }
};

/**
 *
 * @param {*} email
 */
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Helper function for getting true distance of element from top.
 */
export const getOffsetTop = (elem) => {
  let distance = 0;

  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }

  // Return the distance.
  return distance < 0 ? 0 : distance;
};

/**
 * Gets the subtitle for an article.
 *
 * @param {*} terms
 * @param {*} location
 */
export const getSubtitle = (terms, location, truncate = false) => {
  const subtitle = [];
  if (location) {
    subtitle.push(location);
  }

  const announcedDate = findKeyTermsMatching(terms, /announce/i);
  const operationalDate = findKeyTermsMatching(terms, /operational/i);

  const date = announcedDate
    ? `Announced: ${announcedDate}`
    : operationalDate
    ? `Operational: ${operationalDate}`
    : null;
  if (date) {
    subtitle.push(truncate ? date.substring(0, 50) : date);
  }

  return subtitle.join(', ');
};

/**
 *
 * @param {*} terms
 * @param {*} regex
 */
export const findKeyTermsMatching = (terms, regex) => {
  const matchingTerm = terms.find((term) => {
    // Terms is polymorphic since this supports JSONAPI as well as Elastic
    // Search shape.
    if (term.relationships) {
      return term.relationships.keyTerm.name.match(regex);
    } else {
      return term.termName.match(regex);
    }
  });
  return matchingTerm ? matchingTerm.body : null;
};
